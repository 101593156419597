import React from "react";
// import remove from "../assets/plus.svg";
// import { postBonusChange } from "../api/api";
import coinImg from "../assets/coin.svg";
import { bonusChange } from "../utils/mocked.api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalCartDetail = ({
  modalInfo,
  setModalInfo,
  handleChangeBonusCredit,
  balance,
}) => {
  console.log(modalInfo, "modalInfo");

  const handleClose = () => {
    setModalInfo(modalInfo.show === false);
  };

  const handleChange = () => {
    if (balance >= modalInfo.item.price) {
      handleChangeBonusCredit(
        modalInfo.item.price,
        `Списання ${modalInfo.item.price} бонусів за обмін товару комбінацію`
      );
      toast(
        `Ви обміняли ${modalInfo.item.price} бонусів на ${modalInfo.item.title}`
      );
    } else {
      toast(
        `У вас недостаттня кількість бонусів для  обміну, повертаєтесь в гру та заробляйте більше бонусів`
      );
    }

    // console.log("start");
    // const data = await postBonusChange({
    //   id: 2,
    //   qty: QTY,
    //   type: `credit`,
    //   reason: `списання бонусів за продукт ${productName}`,
    // });
    // console.log(data);
    // bonusChange({ id: "22", qty: modalInfo.item.price, reason: "списання" });
  };

  return (
    <div className="cartDetailContainer">
      <div className="cartDetailContent">
        {/* <img
          src={remove}
          className="removeCartDetail"
          alt="remove"
          onClick={handleClose}
        /> */}

        <img className="cartDetailImg" src={modalInfo.item.src} alt="item" />

        <div className="contentBlock">
          <h4 className="cartDetailProductName">{modalInfo.item.title}</h4>
          <div className="cartDetailPriceBlock">
            <div className="priceBlock">
              <img src={coinImg} alt="coin" />
              <span className="cartDetailPrice">
                {modalInfo.item.price} ({modalInfo.item.price / 100} гривень)
              </span>
            </div>
          </div>
          <div className="cartDetailDescription">
            Цей товар ви можете обміняти на бонуси, які отримали у грі Вгадайка
          </div>
          <div className="cartDetailPriceBlock"></div>
          <div className="cartDetailContentButtonBlock">
            <button className="cartDetailContentBack" onClick={handleClose}>
              Назад
            </button>
            <button className="cartDetailContentButton" onClick={handleChange}>
              Обміняти
            </button>
          </div>
          <ToastContainer toastStyle={{ color: "#383838", fontSize: 15 }} />
        </div>
      </div>
    </div>
  );
};

export default ModalCartDetail;
