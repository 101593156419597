import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URI;

const authHeader = "Basic " + btoa("sw_obmin:sw_obmin");

const makeRequest = async (endpoint, data) => {
  try {
    const response = await axios.post(
      "https://scripts.cloudmallbot.online/universal_request.php",
      {
        server_url: SERVER_URL,
        authorization: authHeader,
        endpoint: endpoint,
        data: data,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

// export const test = async () => {
//   try {
//     const res = await axios.get(
//       "https://scripts.cloudmallbot.online/universal_request.php"
//     );

//     return res.data;
//   } catch (err) {
//     console.error(err);
//   }
// };

export const auth = async (payload) => {
  try {
    const response = await makeRequest("auth", payload);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const postBonusChange = async (payload) => {
  try {
    const response = await makeRequest("bonus-change", payload);
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getBonusProduct = async () => {
  return;
  // try {
  //   const response = await makeRequest("prizes", null);
  //   return response;
  // } catch (err) {
  //   console.error(err);
  // }
};
