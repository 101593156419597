export const prize = {
  ok: true,
  data: [
    {
      id: "esgsdgsg",
      price: 200,
      qty: 7,
      title: "Косметика",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "sf2342sd",
      price: 150,
      qty: 1,
      title: "Гель для душу",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "s24242s1",
      price: 500,
      qty: 5,
      title: "Подарунковий набір",
      image:
        "https://inly.ua/image/cache/catalog/%D0%BC%D1%96%D0%BD%D1%96%D0%B0%D1%82%D1%8E%D1%80%D0%B8%20%D0%BD%D0%B0%D0%B1%D0%BE%D1%80%D0%B8/%D0%A4%D0%BE%D1%82%D0%BE%2004.07.23,%2013%2024%2034-1000x1000.jpg",
    },
    {
      id: "bb4242s1",
      price: 100,
      qty: 50,
      title: "Мило",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "99992s1",
      price: 50,
      qty: 20,
      title: "Смаколики",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "99992s1",
      price: 150,
      qty: 20,
      title: "Іграшка",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "99992s1",
      price: 120,
      qty: 20,
      title: "Зарядка",
      image: "https://picsum.photos/300/300",
    },
    {
      id: "99992s1",
      price: 500,
      qty: 10,
      title: "Блокнот",
      image: "https://picsum.photos/300/300",
    },
  ],
};
